let timetaplePage = document.querySelector('.timetable-calendar');

document.querySelectorAll('.timetable-calendar__hours-item').forEach((item) =>
  item.addEventListener('click', function () {
    let popup = document.querySelector('.timetable-calendar__popup');
    popup.style.display = 'block';
    popup.querySelector('.timetable-calendar__popup-container-title').innerText = `${
      item.querySelector('.timetable-calendar__hours-item-title').innerText
    }`;
    document.querySelector('html').style.overflow = 'hidden';
  })
);

if (document.getElementById('close-popup-bg')) {
  document.getElementById('close-popup-bg').addEventListener('click', function () {
    document.querySelector('html').style.overflow = 'auto';
    document.querySelector('.timetable-calendar__popup').style.display = 'none';
  });
}
if (document.getElementById('close-popup-svg')) {
  document.getElementById('close-popup-svg').addEventListener('click', function () {
    document.querySelector('html').style.overflow = 'auto';
    document.querySelector('.timetable-calendar__popup').style.display = 'none';
  });
}

import Swiper, { Pagination, Navigation, Thumbs, Controller } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

Swiper.use([Pagination, Controller, Navigation, Thumbs]);

let swiperMain = new Swiper('.swiper__main', {
  slidesPerView: 1,
  initialSlide: 0,
  loop: true,
  centeredSlides: true,
  spaceBetween: 30,
  slideToClickedSlide: true,
  on: {
    slideChange(e) {
      if (e.slides[e.activeIndex].querySelector('.account-card__item-time')) {
        e.slides[e.activeIndex].querySelector('.account-card__item-time').style.display = 'flex';
      } else {
        e.slides.forEach((slide) => {
          if (slide.querySelector('.account-card__item-time')) {
            slide.querySelector('.account-card__item-time').style.display = 'none';
          }
        });
      }
    },
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  breakpoints: {
    429: {
      slidesPerView: 1.3,
    },
  },
});

let sliderInfoLinks = new Swiper('.slider-info__links', {
  slidesPerView: 2.2,
  initialSlide: 0,
  spaceBetween: 12,
  breakpoints: {
    429: {
      slidesPerView: 'auto',
      spaceBetween: 30,
      centeredSlides: false,
    },
  },
});

let swiperDirectionsImage = '';
let swiperDirectionsLink = '';

document?.querySelectorAll('.swiper__directions-link').forEach((directionsLink) => {
  swiperDirectionsLink = new Swiper(directionsLink, {
    slideToClickedSlide: true,
    loop: true,
    loopedSlides: 10,
    breakpoints: {
      0: {
        slidesPerView: 1.5,
        // spaceBetween: 40,
        // slidesPerView: 'auto',
        centeredSlides: true,
      },
      429: {
        slidesPerView: 1.9,
        centeredSlides: true,
      },
      769: {
        slidesPerView: 2,
        centeredSlides: true,
      },
      1025: {
        slidesPerView: 3,
        centeredSlides: true,
      },
      1441: {
        slidesPerView: 4,
      },
      1631: {
        slidesPerView: 5,
        // spaceBetween: 20,
      },
    },
  });
});

document?.querySelectorAll('.swiper__directions-image').forEach((directionsImage) => {
  swiperDirectionsImage = new Swiper(directionsImage, {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    slideToClickedSlide: true,
    loop: true,
    loopedSlides: 10,
    centeredSlides: true,
    breakpoints: {
      0: {
        spaceBetween: 10,
        slidesPerView: 1.14,
      },
      769: {
        slidesPerView: 1.52,
        spaceBetween: 33,
      },
    },
  });
});

if (swiperDirectionsLink) {
  swiperDirectionsLink.controller.control = swiperDirectionsImage;
  swiperDirectionsImage.controller.control = swiperDirectionsLink;
}

let swiperGalleryLink = new Swiper('.swiper__gallery-link', {
  slideToClickedSlide: true,
  loop: true,
  loopedSlides: 10,
  breakpoints: {
    0: {
      slidesPerView: 1.6,
      centeredSlides: true,
    },
    429: {
      slidesPerView: 1.9,
      centeredSlides: true,
    },
    769: {
      slidesPerView: 2,
      centeredSlides: true,
    },
    1025: {
      slidesPerView: 3,
      centeredSlides: true,
    },
    1441: {
      slidesPerView: 4,
    },
    1631: {
      slidesPerView: 5,
    },
    1870: {
      slidesPerView: 6,
    },
  },
});

let swiperGalleryImage = new Swiper('.swiper__gallery-image', {
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  slideToClickedSlide: true,
  loop: true,
  loopedSlides: 10,
  centeredSlides: true,
  breakpoints: {
    0: {
      spaceBetween: 10,
      slidesPerView: 1.14,
    },
    769: {
      slidesPerView: 1.02,
      spaceBetween: 33,
    },
  },
});

if (swiperGalleryLink) {
  swiperGalleryLink.controller.control = swiperGalleryImage;
  swiperGalleryImage.controller.control = swiperGalleryLink;
}

let swiperCard = new Swiper('.swiper__card', {
  slidesPerView: 1.3,
  // spaceBetween: 35,
  breakpoints: {
    429: {
      slidesPerView: 'auto',
      // spaceBetween: 139,
    },
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

let swiperPrivilege = new Swiper('.swiper__privilege', {
  slidesPerView: 1.3,
  // spaceBetween: 92,
  watchOverflow: true,
  breakpoints: {
    429: {
      slidesPerView: 1.4,
      spaceBetween: 60,
    },
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

let swiperServiceDescription = new Swiper('.swiper__service-description-info', {
  slidesPerView: 1.2,
  spaceBetween: 18,
  breakpoints: {
    429: {
      slidesPerView: 2.2,
      spaceBetween: 23,
    },
  },
});

let swiperServiceDescriptionServices = new Swiper('.swiper__service-description-services', {
  slidesPerView: 1.2,
  spaceBetween: 19,
  breakpoints: {
    1024: {
      slidesPerView: 3.5,
      spaceBetween: 38,
    },
  },
});

let swiperTimetableCalendar = new Swiper('.swiper__timetable-calendar', {
  slidesPerView: 1,
  spaceBetween: 19,
  swipeHandler: false,
  breakpoints: {
    769: {
      slidesPerView: 7,
      slidesPerGroup: 7,
      spaceBetween: 0,
      swipeHandler: true,
    },
  },

  on: {
    slideChange: function (e) {
      if (window.screen.width < 769) {
        swiperTimetableCalendardDate.slideTo(e.activeIndex);
        swiperTimetableCalendardDate.swipeDirection = '';
        swiperTimetableCalendardDate.slides.forEach((slide) => {
          slide.classList.remove('timetable-calendar-date__item--active');
        });
        swiperTimetableCalendardDate.slides[e.activeIndex].classList.add('timetable-calendar-date__item--active');
      }
    },
  },
});

let swiperTimetableCalendardDate = new Swiper('.swiper__timetable-calendar-date', {
  slidesPerView: 7,
  slidesPerGroup: 7,
  spaceBetween: 0,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  on: {
    slideChange: function (e) {
      if (window.screen.width > 768) {
        swiperTimetableCalendar.slideTo(e.activeIndex);
      } else {
        if (swiperTimetableCalendardDate.swipeDirection == 'next') {
          swiperTimetableCalendar.slideTo(e.activeIndex);
        }
        if (swiperTimetableCalendardDate.swipeDirection == 'prev') {
          swiperTimetableCalendar.slideTo(e.activeIndex + 6);
        }
      }
    },
  },
  breakpoints: {
    769: {
      slidesPerView: 7,
      slidesPerGroup: 7,
      spaceBetween: 10,
    },
  },
});

//// ================================ Imports ======================================
//scss
// import 'bootstrap/dist/css/bootstrap-grid.css';
import '@fancyapps/ui/dist/fancybox.css';
import 'nouislider/dist/nouislider.css';
import '../scss/index.scss';

//js
import $ from 'jquery';
import { Fancybox, Carousel, Panzoom } from '@fancyapps/ui';
import IMask from 'imask';
import Choices from 'choices.js';
import autoComplete from '@tarekraafat/autocomplete.js';
import validate from 'validate.js';
import ApexCharts from 'apexcharts';
import { rippleEffect, Ripple } from 'data-ripple';
import noUiSlider from 'nouislider';
import Scrollbar from 'smooth-scrollbar';
import './burger.js';
import './slider.js';
import './popupTraining.js';
import ymaps from 'ymaps';
//// ================================ Code ======================================

//timer
let timerContainers = document.querySelectorAll('.account-card__item-time');
timerContainers.forEach((timerContainer) => {
  let time = parseInt(timerContainer.getAttribute('data-time')) - parseInt(Date.now() / 1000);
  let timerChangeAction;
  function timerChange() {
    if (time) {
      let d = Math.floor(time / (3600 * 24));
      if (d < 10) {
        d = '0' + d;
      }
      let h = Math.floor((time % (3600 * 24)) / 3600);
      if (h < 10) {
        h = '0' + h;
      }
      let m = Math.floor((time % 3600) / 60);
      if (m < 10) {
        m = '0' + m;
      }
      let s = Math.floor(time % 60);
      if (s < 10) {
        s = '0' + s;
      }
      timerContainer.querySelector('.account-card__item-time-number--days').innerText = d;
      timerContainer.querySelector('.account-card__item-time-number--hours').innerText = h;
      timerContainer.querySelector('.account-card__item-time-number--minuts').innerText = m;
      timerContainer.querySelector('.account-card__item-time-number--seconds').innerText = s;
      time--;
    }
  }

  timerChangeAction = setInterval(() => {
    if (time == 0) {
      clearInterval(timerChangeAction);
    } else {
      timerChange();
    }
  }, 1000);
});

window.addEventListener('click', (e) => {
  if (
    document.querySelector('.header__body-up-item-disabled').classList.contains('header__body-up-item-disabled--active') &&
    !e.target.classList.contains('header__body-up-item-disabled--active')
  ) {
    document.querySelector('.header__body-up-item-disabled').classList.remove('header__body-up-item-disabled--active');
    document.querySelector('.header__body-up-list-drop').classList.remove('header__body-up-list-drop--active');
    document.querySelector('.header__body-up-list-drop').style.height = 0 + 'px';
  }
});

let cookie = document.querySelector('.cookie');
let closeCookie = document.querySelector('.cookie__close');

if (localStorage.getItem('cookie') != false && localStorage.getItem('cookie') == null) {
  setTimeout(() => {
    cookie.style.display = 'block';
  }, 2000);
}

closeCookie?.addEventListener('click', () => {
  cookie.style.display = 'none';
});

cookie?.querySelector('.cookie__btn').addEventListener('click', () => {
  localStorage.setItem('cookie', false);
  cookie.style.display = 'none';
});

document.querySelectorAll('[data-click-box]')?.forEach((clickBox) => {
  clickBox.addEventListener('click', (el) => {
    if (el.target.getAttribute('data-click-box') == 'phone') {
      document.querySelector('body').style.overflow = 'hidden';
      Fancybox.bind('[data-click-box=phone]', {
        dragToClose: false,
        on: {
          destroy: (e) => {
            document.querySelectorAll('.popup-phone').forEach((activePopup) => {
              if (activePopup.style.display != 'none') {
                let phonePopupActive = activePopup;
                let activeForm = phonePopupActive.querySelector('.application__form');
                phonePopupActive.querySelector('.application__input-change').innerHTML = 'Выберите фитнес-центр';
                phonePopupActive.querySelector('.application__title-error').style.display = 'none';
                phonePopupActive.querySelector('.application__title-send').style.display = 'none';
                phonePopupActive.querySelector('.application__title-not-send').style.display = 'block';
                document.querySelector('body').style.overflow = 'inherit';
                activeForm.reset();
              }
            });
          },
        },
      });
    }
  });
});

Fancybox.bind('[data-fancybox="gallery"]', {});

// let authorized = false;

// if (!authorized) {
//   document.querySelector('.header__authorized').style.display = 'block';
//   document.querySelector('.header__btn').style.display = 'none';
// } else {
//   document.querySelector('.header__authorized').style.display = 'none';
//   document.querySelector('.header__btn').style.display = 'block';
// }

let name = document.getElementById('name');
let phone = document.getElementById('phone-mask');

// let constrainst = {
//   firstName: {
//     presence: true,
//   },
//   phone: {
//     presence: true,
//     length: {
//       is: 17,
//     },
//   },
// };

// let application = document.querySelector('.application');
// let change = '';
// let errorChange = '';
// let errorFirstName = '';
// let errorPhone = '';

let formApplication = document.querySelectorAll('.application__form');
let inpuPhone = document.querySelectorAll('.application__input-phone');
inpuPhone.forEach((phone) => {
  let phoneMask = IMask(phone, {
    mask: '+{7}(000) 000-00-00',
  });
});

document.querySelectorAll('.application__input-change')?.forEach((inputChange) => {
  let applicationSvg = inputChange.parentNode;
  let timer;
  window.addEventListener('click', (e) => {
    if (
      applicationSvg.querySelector('.application__input-change-list').classList.contains('application__input-change-list--active') &&
      !e.target.classList.contains('application__input-change')
    ) {
      applicationSvg.querySelector('.application__input-change-list').classList.remove('application__input-change-list--active');
      applicationSvg.querySelector('.application__svg-container').classList.remove('application__svg-container--active');
      applicationSvg.querySelector('.application__input-change-list').style.height = '0px';
      timer = setTimeout(() => {
        applicationSvg.querySelector('.application__input-change-list').style.display = 'none';
      }, 3000);
      clearTimeout(timer);
    }
  });

  inputChange.addEventListener('click', function (e) {
    if (applicationSvg.querySelector('.application__input-change-list').classList.contains('application__input-change-list--active')) {
      applicationSvg.querySelector('.application__input-change-list').classList.remove('application__input-change-list--active');
      applicationSvg.querySelector('.application__svg-container').classList.remove('application__svg-container--active');
      applicationSvg.querySelector('.application__input-change-list').style.height = '0px';
      timer = setTimeout(() => {
        applicationSvg.querySelector('.application__input-change-list').style.display = 'none';
      }, 3000);
      clearTimeout(timer);
    } else {
      timer = 0;
      applicationSvg.querySelector('.application__input-change-list').classList.add('application__input-change-list--active');
      applicationSvg.querySelector('.application__svg-container').classList.add('application__svg-container--active');
      applicationSvg.querySelector('.application__input-change-list').style.display = 'block';
      applicationSvg.querySelector('.application__input-change-list').style.height =
        applicationSvg.querySelector('.application__input-change-list').scrollHeight + 'px';
      applicationSvg.querySelector('.application__input-change-list').style.top = e.target.offsetHeight + 'px';
    }

    document.querySelectorAll('.application__input-change-item').forEach((inputChangeItem) => {
      inputChangeItem.addEventListener('click', function () {
        applicationSvg.querySelector('.application__input-hidden').value = inputChangeItem.getAttribute('data-value');
        applicationSvg.querySelector('.application__input-change').innerText = inputChangeItem.innerText;
        applicationSvg.querySelector('.application__input-change-list').classList.remove('application__input-change-list--active');
        applicationSvg.querySelector('.application__svg-container').classList.remove('application__svg-container--active');
        applicationSvg.querySelector('.application__input-change-list').style.height = '0px';
        let element = applicationSvg.querySelector('.application__input-hidden');
        let event = new Event('input');
        element.dispatchEvent(event);
      });
    });
  });
});

formApplication?.forEach((form) => {
  form.addEventListener('submit', function (e) {
    e.preventDefault();
    let error = e.target.querySelector('.application__error');
    let errors = false;

    e.target.querySelectorAll('.application__input').forEach((el) => {
      if (el.getAttribute('data-name-form')) {
        name = el;
      }
      if (el.getAttribute('data-phone-form')) {
        phone = el;
      }
    });

    if (e.target.querySelector('.application__input-change').innerText == 'Выберите фитнес-центр') {
      errors = true;
      if (error) {
        error.style.display = 'block';
      }
    } else {
      errors = false;
      if (error) {
        error.style.display = 'none';
      }
    }

    if (phone.value.length == 0) {
      errors = true;
      error.style.display = 'block';
    } else if (phone.value.length != 17) {
      errors = true;
      error.style.display = 'block';
    } else {
      errors = false;
      error.style.display = 'none';
    }

    if (name.value.length == 0) {
      errors = true;
      error.style.display = 'block';
    } else {
      errors = false;
      error.style.display = 'none';
    }

    e.target.querySelectorAll('.application__input-check-error').forEach((input) => {
      if (input.getAttribute('data-value-form')) {
        if (input.value == '') {
          e.target.querySelector('.application__input-change').classList.add('application__input-error');
        } else {
          e.target.querySelector('.application__input-change').classList.remove('application__input-error');
        }
      }
      if (input.getAttribute('data-name-form')) {
        if (input.value.length == 0) {
          input.classList.add('application__input-error');
        } else {
          input.classList.remove('application__input-error');
        }
      }
      if (input.getAttribute('data-phone-form')) {
        if (input.value.length == 0) {
          input.classList.add('application__input-error');
        } else if (input.value.length != 17) {
          input.classList.add('application__input-error');
        } else {
          input.classList.remove('application__input-error');
        }
      }
    });

    if (errors) {
      error.innerHTML = 'Пожалуйста корректно заполните все поля';
    }

    if (!errors && e.target.querySelector('.application__input-change').innerText != 'Выберите фитнес-центр') {
      const formName = e.target.getAttribute('name') ?? 'form_submit';
      const captchaKey = e.target['captcha-key']?.value;

      if(captchaKey) {
        grecaptcha.ready(function () {
          grecaptcha.execute(captchaKey, {action: formName}).then(function (token) {

            let formApplicationInfo = new FormData(form);
            formApplicationInfo.append('token', token);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `${form.action}`);
            xhr.send(formApplicationInfo);
            const submitButton = e.target.querySelector('[data-entity="submit-button"]');
            if (submitButton) {
              submitButton.disabled = true;
            }

            xhr.onload = function () {
              if (xhr.status != 200) {
                e.target.parentNode.parentNode.querySelector('.application__title-not-send').style.display = 'none';
                e.target.parentNode.parentNode.querySelector('.application__title-error').style.display = 'inline-flex';
              } else {
                e.target.parentNode.parentNode.querySelector('.application__title-not-send').style.display = 'none';
                e.target.parentNode.parentNode.querySelector('.application__title-send').style.display = 'inline-flex';
              }
              if (submitButton) {
                submitButton.disabled = false;
              }
            };
            xhr.onerror = function () {
              e.target.parentNode.parentNode.querySelector('.application__title-not-send').style.display = 'none';
              e.target.parentNode.parentNode.querySelector('.application__title-error').style.display = 'inline-flex';
              if (submitButton) {
                submitButton.disabled = false;
              }
            };

          });
        });
      }

    }
  });
});

let formTest = document.getElementById('test-form');

document.querySelectorAll('.test__form-stage')?.forEach((stage) => {
  stage.querySelector('.test__form-btn')?.addEventListener('click', (e) => {
    stage.querySelectorAll('.test__form-input').forEach((input) => {
      if (input.checked) {
        stage.classList.remove('test__form-stage--active');
        stage.nextElementSibling.classList.add('test__form-stage--active');
        stage.querySelector('.test__form-error').innerHTML = '';
      } else {
        stage.querySelector('.test__form-error').innerHTML = 'Выберите вариант ответа';
      }
    });
  });
});

formTest?.addEventListener('submit', (e) => {
  e.preventDefault();
  let errors = false;
  let error = e.target.querySelector('.application__error');
  Array.from(formTest)
    .filter((item) => !!item.name)
    .map((element) => {
      let { name, value, type, checked } = element;
      // if (type == 'radio' && checked) {
      //   console.log({ name, value });
      // }
      if (type != 'radio') {
        if (value == '') {
          element.classList.add('application__input-error');
          errors = true;
        } else {
          element.classList.remove('application__input-error');
          errors = false;
        }
        if (name == 'phone' && value.length != 17) {
          element.classList.add('application__input-error');
          errors = true;
        }
      }
    });
  if (errors) {
    error.innerHTML = 'Пожалуйста корректно заполните все поля';
  } else {
    error.innerHTML = '';
    let formTestInfo = new FormData(formTest);
    let xhr = new XMLHttpRequest();
    xhr.open('POST', `${formTest.action}`);
    xhr.send(formTestInfo);
    xhr.onload = function () {
      if (xhr.status != 200) {
        e.target.parentNode.querySelector('.application__title-not-send').style.display = 'none';
        e.target.parentNode.querySelector('.application__title-error').style.display = 'inline-flex';
      } else {
        e.target.parentNode.querySelector('.application__title-not-send').style.display = 'none';
        e.target.parentNode.querySelector('.application__title-send').style.display = 'inline-flex';
      }
    };
    xhr.onerror = function () {
      e.target.parentNode.querySelector('.application__title-not-send').style.display = 'none';
      e.target.parentNode.querySelector('.application__title-error').style.display = 'inline-flex';
    };
  }
});

document.querySelector('.header__mobile-change-fitnes')?.addEventListener('click', function (e) {
  let changeContainer = document.querySelector('.header__mobile-change-fitnes');
  if (e.target.classList.contains('header__mobile-change-fitnes-item-main')) {
    document.querySelector('.header__mobile-change-fitnes-item-main').classList.toggle('header__mobile-change-fitnes-item-main--active');
    document.querySelector('.header__mobile-change-fitnes-item-svg').style.fill = '#FEED00';
    if (e.target.classList.contains('header__mobile-change-fitnes-item-main--active')) {
      changeContainer.style.height = changeContainer.scrollHeight + 'px';
    } else {
      document.querySelector('.header__mobile-change-fitnes-item-svg').style.fill = '#000000';
      changeContainer.style.height = 35 + 'px';
    }
  } else if (e.target.classList.contains('header__mobile-change-fitnes-item-one')) {
    document.querySelector('.header__mobile-change-fitnes-item-main').innerHTML = `
        <div class="header__mobile-change-fitnes-item-text">РОССИЙСКАЯ, 297</div>
        <svg class='header__mobile-change-fitnes-item-svg' width="22" height="13" viewBox="0 0 22 13" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.402724 0.423044C0.939703 -0.141013 1.8103 -0.141013 2.34728 0.423044L11 9.5128L19.6528 0.423043C20.1897 -0.141014 21.0604 -0.141014 21.5973 0.423043C22.1342 0.9871 22.1342 1.90172 21.5973 2.46578L11.9723 12.5769C11.7145 12.8478 11.3647 13 11 13C10.6354 13 10.2856 12.8478 10.0278 12.5769L0.402724 2.46578C-0.134242 1.90173 -0.134242 0.987101 0.402724 0.423044Z"/>
        </svg>
        `;
    document.querySelector('.header__mobile-change-fitnes-item-main').classList.remove('header__mobile-change-fitnes-item-main--active');
    changeContainer.style.height = 35 + 'px';
  } else {
    document.querySelector('.header__mobile-change-fitnes-item-main').innerHTML = `
        <div class="header__mobile-change-fitnes-item-text">КРАСНЫХ ПАРТИЗАН, 117</div>
        <svg class='header__mobile-change-fitnes-item-svg' width="22" height="13" viewBox="0 0 22 13" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.402724 0.423044C0.939703 -0.141013 1.8103 -0.141013 2.34728 0.423044L11 9.5128L19.6528 0.423043C20.1897 -0.141014 21.0604 -0.141014 21.5973 0.423043C22.1342 0.9871 22.1342 1.90172 21.5973 2.46578L11.9723 12.5769C11.7145 12.8478 11.3647 13 11 13C10.6354 13 10.2856 12.8478 10.0278 12.5769L0.402724 2.46578C-0.134242 1.90173 -0.134242 0.987101 0.402724 0.423044Z"/>
        </svg>
        `;
    document.querySelector('.header__mobile-change-fitnes-item-main').classList.remove('header__mobile-change-fitnes-item-main--active');
    changeContainer.style.height = 35 + 'px';
  }
});

document.querySelectorAll('.menu__item')?.forEach((el) => {
  el.addEventListener('click', function (e) {
    document.querySelectorAll('.menu__sub-list').forEach((element) => {
      document.getElementById('main-list').style.display = 'none';
      if (e.target.getAttribute('data-submenu') == element.getAttribute('data-submenu-name')) {
        element.style.display = 'block';
        document.querySelectorAll('.menu__sub-list-header').forEach((elClose) => {
          elClose.addEventListener('click', function () {
            document.getElementById('main-list').style.display = 'block';
            element.style.display = 'none';
          });
        });
      }
    });
  });
});

document.getElementById('open-popup')?.addEventListener('click', function () {
  if (document.querySelector('.header__body-up-item-disabled').classList.contains('header__body-up-item-disabled--active')) {
    document.querySelector('.header__body-up-item-disabled').classList.remove('header__body-up-item-disabled--active');
    document.querySelector('.header__body-up-list-drop').classList.remove('header__body-up-list-drop--active');
    document.querySelector('.header__body-up-list-drop').style.height = 0 + 'px';
  } else {
    document.querySelector('.header__body-up-item-disabled').classList.add('header__body-up-item-disabled--active');
    document.querySelector('.header__body-up-list-drop').classList.add('header__body-up-list-drop--active');
    document.querySelector('.header__body-up-list-drop').style.height = document.querySelector('.header__body-up-list-drop').scrollHeight + 0 + 'px';
  }
});

let dataCoorinatsSelector = document.querySelectorAll('[data-coordinats]');
ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU').then((maps) => {
  if (dataCoorinatsSelector.length > 0) {
    dataCoorinatsSelector.forEach((el) => {
      let map = new maps.Map(`${el.id}`, {
        center: el.getAttribute('data-coordinats').split(','),
        zoom: 17,
      });
      map.geoObjects.add(new maps.Placemark(el.getAttribute('data-coordinats').split(',')));
    });
  }
});

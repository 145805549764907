import { event } from "jquery";

document.querySelector('.header__mobile-burger')?.addEventListener('click', () => {
  document.querySelectorAll('.header__mobile-burger-line').forEach((item, index) => {
    if (index == 0) {
      item.classList.toggle('header__mobile-burger-line--none');
    } else if (index == 1) {
      item.classList.toggle('header__mobile-burger-line--rotate-one');
    } else {
      item.classList.toggle('header__mobile-burger-line--rotate-two');
    }
  });
  document.querySelector('body').classList.toggle('body--hidden');
  document.querySelector('.menu').classList.toggle('menu--active');
});

